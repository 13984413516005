import React, { lazy, Suspense, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import news2 from "../../all__news4.json";
import { useGlobalLocation } from "../../shared/LocationContext";
import {
  idsMonths,
  daysIsPost,
  daysIsNotPost,
  monthSerb,
  calendarYears,
  manualDateEaster,
  redDaysId,
  easterDays,
} from "../../shared/shared";
import { inCalendarArr } from "../../shared/sharedAds";
import SimpleButton from "../../UI/Buttons/SimpleButton";
import TimeFormat from "../TimeFormat/TimeFormat";
import "./Calendar.scss";

const AdManagerSlotLazy = lazy(() => import("../AdvModule/AdManagerSlot"));

export default function Calendar(props) {
  const currentDate = new Date();
  let { id, slug } = useParams();
  const location = useGlobalLocation();
  const [isYear, setIsYear] = useState(() => {
    if (slug) {
      return slug;
    } else {
      return currentDate.getFullYear();
    }
  });

  const easter = isYear - 2020;
  const [isMonth, setIsMonth] = useState(() => {
    if (id === undefined) {
      return currentDate.getMonth();
    } else {
      return monthSerb.indexOf(id);
    }
  });
  const [holidays, setHolidays] = useState(filterHolidays);

  const [dropDownYear, setDropDownYear] = useState(false);
  function filterHolidays() {
    let setHol = news2.slice(idsMonths[isMonth][0], idsMonths[isMonth][1]);
    let setHolTest = setHol.map((item, index) => {
      let date1 = new Date(isYear, isMonth, index + 1);
      item.date = date1;
      let date2 = new Date(`${isYear}-${manualDateEaster[easter]}`);
      if (date2.toDateString() === date1.toDateString()) {
        item.title = easterDays[easterDays.length - 1];
      }
      return item;
    });
    return setHolTest;
  }

  useEffect(() => {
    setHolidays(filterHolidays);
  }, [isMonth, isYear]);

  const navigate = useNavigate();

  const changeMonth = (val, secParam) => {
    if (id === undefined) {
      navigate(
        `/crkveni-kalendar/${secParam ? secParam : isYear}/${tableTitle(val)}`
      );
    } else {
      if (isMonth + val === 12) {
        navigate(
          `../crkveni-kalendar/${secParam ? secParam : +isYear + 1}/januar`
        );
      } else if (isMonth === 0 && val === -1) {
        navigate(
          `../crkveni-kalendar/${secParam ? secParam : +isYear - 1}/decembar`
        );
      } else {
        navigate(
          `../crkveni-kalendar/${secParam ? secParam : isYear}/${tableTitle(
            val
          )}`
        );
      }
    }
    if (secParam) {
      setIsYear(secParam);
    } else {
      if (isMonth === 11 && val === 1) {
        setIsMonth(0);
        setIsYear(+isYear + 1);
      } else if (isMonth === 0 && val === -1) {
        setIsMonth(11);
        setIsYear(+isYear - 1);
      } else {
        setIsMonth(isMonth + val);
      }
    }
  };
  function setMonth(short) {
    if (short) {
      //short month on home page
      const setShortCal = () => {
        if (currentDate.getDate() < 7) {
          return holidays.slice(0, currentDate.getDate() + short);
        } else {
          return holidays.slice(
            currentDate.getDate() - short,
            currentDate.getDate() + short
          );
        }
      };
      return setShortCal();
    } else {
      return holidays;
    }
  }
  const tableTitle = (x) => {
    if (id === undefined) {
      if (isMonth + x === 12) {
        return `JANUAR (${+isYear + 1})`;
      } else if (isMonth + x === -1) {
        return `${monthSerb[11]} (${+isYear + x})`;
      } else {
        return monthSerb[isMonth + x];
      }
    } else {
      if (isMonth + x === 12) {
        let e = +isYear;
        return `${monthSerb[0]} (${+isYear + x})`;
      } else if (isMonth + x === -1) {
        return `${monthSerb[11]} (${+isYear + x})`;
      } else {
        return monthSerb[monthSerb.indexOf(id) + x];
      }
    }
  };

  const rowClasses = (index) => {
    return redDaysId.includes(index) ? "redRow" : "";
  };

  const todayClass = (x) => {
    if (
      x.getDate() === currentDate.getDate() &&
      x.getMonth() === currentDate.getMonth()
    ) {
      return " today";
    } else {
      return "";
    }
  };
  const setPostDays = (dateInfo) => {
    let setDateFromDateInfo = new Date(dateInfo);

    //start - Bozic i Bozicni post
    let bozicniPostStart = new Date(isYear, 10, 28); //pocetak posta korigovati!!!
    let bozicniPostEnd = new Date(isYear, 0, 6);

    let mrsniDaniPosleBozica = new Date(isYear, 0, 17);
    let notPost = daysIsNotPost.map((item) => {
      return new Date(isYear, item[0], item[1]).setHours(0, 0, 0, 0);
    });
    let isPost = daysIsPost.map((item) => {
      return new Date(isYear, item[0], item[1]).setHours(0, 0, 0, 0);
    });
    let setDateDay = setDateFromDateInfo.getDay();
    if (
      setDateFromDateInfo >= bozicniPostStart ||
      setDateFromDateInfo <= bozicniPostEnd
    ) {
      return "post";
    } else if (setDateFromDateInfo <= mrsniDaniPosleBozica) {
      return "";
    } else if (setDateDay === 3 || setDateDay === 5) {
      if (!notPost.includes(setDateFromDateInfo.setHours(0, 0, 0, 0))) {
        return "post";
      }
    } else if (isPost.includes(setDateFromDateInfo.setHours(0, 0, 0, 0))) {
      return "post";
    } else {
      return "";
    }
  };
  const setCloseClass = () => {
    if (location.pathname === "/") {
      return " close";
    } else {
      return "";
    }
  };

  //change the calendar year
  const items_list = (items) => {
    return (
      <ul className={getDropDownMenu()}>
        {items.map((item, index) => {
          return (
            <SimpleButton
              key={index}
              clicked={() => {
                setDropDownYear(false);
                changeMonth(0, item.title);
              }}
            >
              {item.title}
            </SimpleButton>
          );
        })}
      </ul>
    );
  };

  const getDropDownMenu = () => {
    return dropDownYear ? "drop_down_menu" : "drop_down_menu close";
  };
  let inTextNumber = 0;
  const nedelje = [];
  return (
    <div className="calendar">
      {/* ---- Gornje ranfle kalendara ---- */}
      <div className="first">
        <h1>Crkveni pravoslavni kalendar</h1>
        <div
          className={`yearBox${setCloseClass()}`}
          onClick={() => {
            setDropDownYear(true);
          }}
          onMouseLeave={() => {
            setDropDownYear(false);
          }}
        >
          <b>{isYear}</b>
          <i class="fa-solid fa-square-caret-down"></i>
          <div className="botDiv">{items_list(calendarYears[0].item_list)}</div>
        </div>
      </div>
      <div className={`calendar-month${setCloseClass()}`}>
        <div>
          <SimpleButton clicked={() => changeMonth(-1)}>
            <i className="fa-solid fa-backward"></i>
            {tableTitle(-1)}
          </SimpleButton>
        </div>
        <div className="month-center"></div>
        <div>
          <SimpleButton clicked={() => changeMonth(1)}>
            {tableTitle(1)}
            <i className="fa-solid fa-forward"></i>
          </SimpleButton>
        </div>
      </div>
      {/* ---- END Gornje ranfle kalendara ---- */}

      {/* ---- Kalendar ---- */}
      <table className="calendar-table" cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            {["Dani", "Novi", "Stari"].map((item, index) => {
              return (
                <th key={index}>
                  <span>{item}</span>
                </th>
              );
            })}
            <th>
              <h2>
                {tableTitle(0)} {isYear}
              </h2>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {setMonth(props.shortCal).map((item, index) => {
            let z = new Date(item.date);
            let zz = z.getDay();
            const tdClasses = ["onlyDay", "noDay", "before"];
            if (zz === 1) {
              nedelje.push(inTextNumber);
              inTextNumber++;
              return (
                <>
                  <tr className="opisNedelje">
                    <td colSpan={5}>
                      {[1, 2, 3, 4, 5].includes(inTextNumber) && (
                        <div className="banner-wrapper calendar">
                          <Suspense fallback={<div></div>}>
                            <AdManagerSlotLazy
                              adUnitPath={location.pathname}
                              slotNumber={inCalendarArr[inTextNumber - 1]}
                            />
                          </Suspense>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr
                    key={index}
                    className={
                      rowClasses(item.id) + todayClass(new Date(item.date))
                    }
                  >
                    {tdClasses.map((x, index) => {
                      return (
                        <td key={index}>
                          <TimeFormat timePost={item.date} classes={x} />
                        </td>
                      );
                    })}
                    <td>
                      <div className="test">
                        <h3>{item.title}</h3>
                      </div>
                    </td>
                    <td>{setPostDays(item.date)}</td>
                  </tr>
                </>
              );
            } else {
              return (
                <tr
                  key={index}
                  className={
                    rowClasses(item.id) + todayClass(new Date(item.date))
                  }
                >
                  {tdClasses.map((x, index) => {
                    return (
                      <td key={index}>
                        <TimeFormat timePost={item.date} classes={x} />
                      </td>
                    );
                  })}
                  <td>
                    <div className="test">
                      <h3>{item.title}</h3>
                    </div>
                  </td>
                  <td>{setPostDays(item.date)}</td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
      {/* ---- END Kalendar ---- */}

      {/* ---- Donja ranfla kalendara ---- */}
      <div className="calendar-month">
        {[[-1, "fa-backward"], [0], [1, "fa-forward"]].map((item, index) => {
          return (
            <div key={index}>
              <SimpleButton clicked={() => changeMonth(item[0])}>
                <i className={`fa-solid ${item[1]}`}></i>
                <span>{tableTitle(item[0])}</span>
              </SimpleButton>
            </div>
          );
        })}
      </div>
      {/* ---- END Donja ranfla kalendara ---- */}
    </div>
  );
}
